<template>
    <div class="ma-auto">
        <div class="d-flex align-center justify-center mb-4">

            <div class="error-title" :class="$vuetify.breakpoint.smAndUp ? 'mt-8' : ''">
                <div class="text-18 font-weight-bold">            
                    {{ loggedInUser.userDetails.displayName }}
                </div>
                <div class="text-16 font-weight-bold">            
                    {{ loggedInUser.userDetails.email }}
                </div>
                <div class="text-14 font-weight-bold mb-5">            
                    Confirmation ID: {{ sessionId }}
                </div>
                <div v-if="verified" class="text-18 font-weight-bold mb-5">            
                    Payment Confirmed!
                </div>
                <div v-else class="text-18 font-weight-bold mb-5">            
                    {{ awaitingConfirmationMessage }}
                </div>
                <div v-if="!failed" class="text-14 font-weight-bold">            
                    Please keep a record of your confirmation ID in case of any payment disputes <br/>
                    You should receive an email copy shortly
                </div>
                <div v-if="redirecting" class="text-14 font-weight-bold">            
                    Redirecting in {{ redirectionSeconds }}
                </div>
            </div>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <v-btn small class="ma-2" outlined color="primary" @click="goToHome"
                >Back To Home</v-btn
            >
            <v-btn small class="ma-2" color="primary" @click="goToSelection"
                >Continue to selection</v-btn
            >
            <v-btn small class="ma-2" outlined color="danger" @click="goToFAQ"
                >Report a problem</v-btn
            >
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'Success',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Success'
    },
    data(){
        return {
            sessionId: null,
            verified: false,
            loading: true,
            failed: false,
            redirecting: false,
            redirectionSeconds: 30
        }
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        if (sessionId) {
            this.verifyPayment(sessionId);
        }
    },
    mounted(){
        this.sessionId = this.$router.currentRoute.query.session_id;
    },
    methods: {
        ...mapActions(["updatePaymentStatus"]),
        goToHome(){
            this.$router.push("/app/home")
        },
        goToSelection(){
            this.$router.push("/app/hurling/selection/current")
        },
        goToFAQ(){
            this.$router.push('/help/faq/support')
        },
        goToFailure(){
            this.$router.push("/app/payment/failure")
        },
        redirect(){
            this.timingInterval = setInterval(this.countdown, 1000);
        },
        countdown(){
            if(this.redirectionSeconds > 0){
                this.redirectionSeconds = this.redirectionSeconds -1;
            }
            else{
                clearInterval(this.timingInterval)
                this.goToHome();
            }
        },
        async verifyPayment(sessionId) {
            try {
                const response = await fetch(`https://server.knowthegame.ie/verify-payment?session_id=${sessionId}&player_email=${this.loggedInUser.userDetails.email}`);
                //const response = await fetch(`http://localhost:8080/verify-payment?session_id=${sessionId}&player_email=${this.loggedInUser.userDetails.email}`);
                const responseData = await response.json();
                if (response.status == 200 && responseData == true) {
                    this.updatePaymentStatus();
                    this.verified = true;
                    this.redirect();
                    this.redirecting = true;
                    // Here, you might update the UI accordingly or perform other actions
                    // For example, showing a confirmation message, updating user's account status, etc.
                } else {
                    // Handle server errors or unsuccessful verification
                    console.error("Verification failed", responseData.message);
                    this.failed = true;
                    // You might want to inform the user or log these errors
                }
            } catch (error) {
                console.error("Error verifying payment:", error);
                this.failed = true;
                // Handle errors related to the fetch request itself (e.g., network errors)
            }
        }
    },
    computed: {
      ...mapGetters(["loggedInUser"]),
      awaitingConfirmationMessage(){
        return this.failed ? 'Verification Failed. Please contact support' : 'Confirming... Please Wait'
      }
    }
      
}
</script>
<style lang="scss" scoped>
.error-title {
    h1 {
        font-size: 5rem;
    }
}
</style>
